<script setup>

</script>

<template>
  <div class="flex">
    <main class="flex-1 mt-4">
        <slot/>
    </main>
  </div>
</template>

<style scoped>
*, html {
  font-family: 'DM Sans', sans-serif ;
}
</style>
